import React from "react"
import Image from "gatsby-image"
import { Transition } from "@headlessui/react"
import { Link, graphql, useStaticQuery } from "gatsby"

const Widget = () => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const { widget } = useStaticQuery(graphql`
    query {
      widget: sanityContactWidget {
        heading
        description
        optInLanguage
        btnText
        image {
          asset {
            fluid(maxWidth: 850) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <div className="fixed bottom-2 sm:bottom-4 right-2 sm:right-4">
        {/* hidden sm:block */}
        <button
          type="button"
          onClick={() => setModalOpen(true)}
          className="p-3 bg-gradient-to-tr from-green-600 via-green-600 to-green-500 rounded-full shadow-xl"
        >
          {/* <svg className="w-16 h-16 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path>
          </svg> */}
          <svg
            className="w-10 sm:w-14 h-10 sm:h-14 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
            ></path>
          </svg>
        </button>
      </div>

      <form name="contact" netlify="true" netlify-honeypot="bot-field" hidden>
        <input type="text" name="name" />
        <input type="email" name="email" />
        <textarea name="message"></textarea>
      </form>

      <Transition
        show={modalOpen}
        className="fixed z-30 inset-0 overflow-y-auto"
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0 transition-opacity"
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </Transition.Child>
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="inline-block w-full align-bottom bg-white rounded-lg px-0 sm:px-4 pt-0 sm:pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="absolute top-0 z-30 right-0 mt-1 sm:pt-2 pr-1 sm:pr-4">
              {/* hidden sm:block  */}
              <button
                type="button"
                onClick={() => setModalOpen(false)}
                className="text-white bg-gray-700 bg-opacity-50 p-1 sm:p-0 rounded-full sm:bg-transparent sm:text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                aria-label="Close"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="relative bg-white sm:mt-6">
              <div className="lg:absolute lg:inset-0">
                <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                  <Image
                    fluid={widget.image.asset.fluid}
                    className="h-48 w-full sm:max-w-xl sm:mx-auto lg:w-full object-cover lg:absolute lg:h-full rounded-t-md rounded-b-none sm:rounded-md"
                    alt="Ashbrook Land Contact Form Cover Photo"
                  />
                  {/* <img className="h-48 w-full object-cover lg:absolute lg:h-full rounded-md" src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80" alt=""/> */}
                </div>
              </div>
              <div className="relative sm:mt-5 lg:mt-0 pt-6 pb-0 px-4 sm:pt-0 sm:px-6 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
                <div className="lg:pr-8">
                  <div className="max-w-md mx-auto sm:max-w-xl lg:mx-0">
                    <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
                      {widget.heading}
                    </h2>
                    <p className="hidden sm:block mt-3 text-lg leading-7 text-gray-500">
                      {widget.description}
                    </p>
                    <form
                      method="POST"
                      action="https://ashbrookra.activehosted.com/proc.php"
                      id="_form_5_"
                      noValidate
                      className="mt-4 sm:mt-6 grid grid-cols-1 gap-y-2 sm:gap-y-4 sm:grid-cols-2 sm:gap-x-8"
                    >
                      <input type="hidden" name="u" value="5" />
                      <input type="hidden" name="f" value="5" />
                      <input type="hidden" name="s" />
                      <input type="hidden" name="c" value="0" />
                      <input type="hidden" name="m" value="0" />
                      <input type="hidden" name="act" value="sub" />
                      <input type="hidden" name="v" value="2" />
                      <input
                        type="text"
                        name="field[4]"
                        placeholder="State"
                        className="hidden"
                      />

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="fullname"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Name
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            required
                            id="fullname"
                            name="fullname"
                            type="text"
                            className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            id="email"
                            required
                            name="email"
                            type="email"
                            className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Phone Number (Optional)
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            id="phone"
                            name="phone"
                            type="tel"
                            minLength={10}
                            maxLength={10}
                            pattern="[0-9]{10}"
                            placeholder="5128641231"
                            className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="flex justify-between">
                          <label
                            htmlFor="message"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            How can we help you?
                          </label>
                          <span className="text-sm leading-5 text-gray-500">
                            Max. 500 characters
                          </span>
                        </div>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <textarea
                            id="message"
                            name="field[5]"
                            required
                            rows={global?.innerWidth <= 760 ? 2 : 4}
                            className="form-textarea block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                          ></textarea>
                        </div>
                        <div className="mt-1">
                          <p className="text-xs text-gray-500">
                            {widget.optInLanguage}{" "}
                            <Link to="/privacy-policy" className="underline">
                              Privacy Policy
                            </Link>{" "}
                            &{" "}
                            <Link to="/terms" className="underline">
                              Terms
                            </Link>
                          </p>
                        </div>
                      </div>
                      <div className="sm:col-span-2 mt-3 sm:mt-4 sm:flex sm:flex-row-reverse justify-start">
                        <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                          <button
                            type="submit"
                            className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                          >
                            {widget.btnText}
                          </button>
                        </span>
                        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                          <button
                            type="button"
                            onClick={() => setModalOpen(false)}
                            className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                          >
                            Cancel
                          </button>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition>
    </div>
  )
}

export default Widget
