export const filterProperties = (properties, filters) => {  
  return properties.filter((property) => {
    for (let filter in filters) {     
      if (filter === "acres") {
        switch(filters[filter]) {
          case "All":
            return true;
          case "5":
            return property[filter] <= 5.00;
          case "20":
            return property[filter] > 5.00 && property[filter] <= 20.00;
          default:
            return property[filter] > 20.00 && property[filter] < Infinity;
        }
      }
      if (property.property_details[filter] !== filters[filter] && filters[filter] !== 'All') return false;
    }
    return true;
  });
};

// Usage: properties.nodes.keepActiveProperties().map(...)
export const keepActiveProperties = (properties) => { // Filter out SOLD properties
  return properties.filter((property) => property.sold === false);
};

// Usage: properties.nodes.keepFeaturedProperties().map(...)
export const keepFeaturedProperties = (properties) => { // Filter out UNFEATURED properties
  return properties.filter((property) => property.featured === true);
};

// Usage: showSoldPropertiesLast(properties).map(...)
export const showSoldPropertiesLast = (properties) => { // Arrange properties so SOLD is last
  return properties.sort((a, b) => {                
    if (a.sold && !b.sold) return 1;
    else if (!a.sold && b.sold) return -1;
    else return 0;
  })
};