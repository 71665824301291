import React from "react"
import Image from "gatsby-image"
import { Link, graphql, useStaticQuery } from "gatsby"
import {
  keepActiveProperties,
  keepFeaturedProperties,
} from "../utils/properties"

import SocialIcons from "./social-icons"

const Footer = () => {
  const bgColor = "bg-green-800"
  const headingColor = "text-white"
  const bodyColor = "text-white"
  const bodyColorHover = "hover:text-gray-300"
  const bodyColorActive = "text-white"

  const { properties, settings } = useStaticQuery(graphql`
    query {
      settings: sanitySettings {
        phone
        email
        businessName
        businessDescription
        logo {
          asset {
            fluid(maxWidth: 325) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      properties: allSanityProperty(filter: { hidden: { eq: false } }) {
        nodes {
          _id
          sold
          title
          featured
          slug {
            current
          }
        }
      }
    }
  `)

  return (
    <footer className={`${bgColor}`}>
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-6 xl:col-span-1">
            <Image
              className="w-4/5 sm:w-80 mx-auto sm:mx-0"
              fluid={settings.logo.asset.fluid}
              alt="Ashbrook Land Co Logo - White"
            />
            <p className={`${bodyColor} text-base leading-6`}>
              {settings.businessDescription}
            </p>
            <SocialIcons className="text-white hover:text-gray-200" />
          </div>
          <div className="mt-12 grid md:grid-cols-4 gap-8 xl:mt-0 xl:col-span-2">
            <div className="col-span-1">
              <div>
                <h4
                  className={`text-sm leading-5 font-semibold ${headingColor} tracking-wider uppercase`}
                >
                  Contact
                </h4>

                <div className="mt-4">
                  <dl
                    className={`mt-2 text-base leading-6 space-y-4 ${bodyColor}`}
                  >
                    <div>
                      <dt className="sr-only">Company name</dt>
                      <dd>{settings.businessName}</dd>
                    </div>
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd>{settings.email}</dd>
                    </div>
                    <div className="mt-1">
                      <dt className="sr-only">Phone Number</dt>
                      <dd>{settings.phone}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-0 col-span-1 md:ml-9">
              <h4
                className={`text-sm leading-5 font-semibold ${headingColor} tracking-wider uppercase`}
              >
                Company
              </h4>
              <ul className="mt-4 space-y-4">
                <li>
                  <Link
                    to="/"
                    activeClassName={`${bodyColorActive} font-medium text-white`}
                    className={`text-base leading-6 ${bodyColor} ${bodyColorHover}`}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/properties"
                    className={`text-base leading-6 ${bodyColor} ${bodyColorHover}`}
                  >
                    Properties
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className={`text-base leading-6 ${bodyColor} ${bodyColorHover}`}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className={`text-base leading-6 ${bodyColor} ${bodyColorHover}`}
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-4 md:mt-0 col-span-2">
              <div className="md:col-span-2">
                <h4
                  className={`text-sm leading-5 font-semibold ${headingColor} tracking-wider uppercase`}
                >
                  Featured Properties
                </h4>
                <ul className="mt-4 space-y-4">
                  {keepFeaturedProperties(
                    keepActiveProperties(properties.nodes)
                  )
                    .slice(0, 4)
                    .map((property, index) => (
                      <li key={`property-link-${index}`}>
                        <Link
                          key={properties._id}
                          to={`/properties/${property.slug.current}`}
                          className={`text-base leading-6 ${bodyColor} ${bodyColorHover}`}
                        >
                          {property.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className={`text-base leading-6 ${headingColor} xl:text-center`}>
            © 2020 {settings.businessName}. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
