import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

// import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
// import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
// import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";

import { AiFillFacebook } from "@react-icons/all-files/ai/AiFillFacebook";
import { AiOutlineInstagram } from "@react-icons/all-files/ai/AiOutlineInstagram";
import { AiFillYoutube } from "@react-icons/all-files/ai/AiFillYoutube";

const getIcon = (socialName) => {
  let Icon;

  switch(socialName) {
    case "facebook":
      // Icon = FaFacebookSquare;
      Icon = AiFillFacebook;
      break;
    case "youtube": 
      // Icon = FaYoutube;
      Icon = AiFillYoutube;
      break;
    case "instagram":
    default:
      // Icon = FaInstagram;
      Icon = AiOutlineInstagram;
      break;          
  }

  return Icon;
}

const SocialIcons = ({ className = ""}) => {
  const data = useStaticQuery(graphql`
    query {
      sanitySettings {
        socials {
          _key
          name
          link
        }
      }
    }
  `);
  const { socials } = data.sanitySettings;

  return (
    <div className="flex space-x-5">
      {socials.map(social => {
        const Icon = getIcon(social.name);
        return (
          <a key={social._key} href={social.link} className={`${className ? className : "text-gray-300 hover:text-gray-50"}`}>
            <span className="sr-only">{social.name}</span>
            <Icon className="h-5 w-5" />        
          </a>
        );
      })}          
    </div>
  )
}

export default SocialIcons
