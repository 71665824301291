import Image from "gatsby-image"
import React, { useState } from "react"
import { Transition } from "@tailwindui/react"
import { Link, graphql, useStaticQuery } from "gatsby"

import SocialIcons from "./social-icons"

const linkStyles =
  "text-white hover:text-gray-300 focus:outline-none focus:text-gray-200 text-base leading-6 font-medium transition ease-in-out duration-200"

const Header = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [openFlyoutMenu, setOpenFlyoutMenu] = useState(null)

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      settings: sanitySettings {
        phone
        logo {
          asset {
            small: fixed(width: 160) {
              ...GatsbySanityImageFixed
            }
            large: fixed(width: 250) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
    }
  `)

  return (
    <header className="z-30 relative h-16 md:h-24 shadow flex items-center bg-green-800">
      <div className="relative block z-10 flex-1">
        <div className="max-w-6xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-6 lg:px-8 md:space-x-10">
          <div>
            {/* <Image className="h-8 w-auto sm:h-10" fluid={data.settings.logo.asset.fluid} alt="Ashbrook Land Logo with Mountains" /> */}
            <Link to="/" className="hidden md:block">
              <Image
                fixed={data.settings.logo.asset.large}
                alt="Ashbrook Land Logo with Mountains"
              />
            </Link>
            <Link to="/" className="-ml-4 md:hidden block">
              <Image
                fixed={data.settings.logo.asset.small}
                alt="Ashbrook Land Logo with Mountains"
              />
            </Link>
          </div>

          <div className="-mr-2 -my-2 md:hidden">
            <button
              onClick={() => setIsMobileNavOpen(true)}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-300 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>

          <div className="hidden md:flex md:items-center md:justify-between md:space-x-12">
            <nav className="flex items-center space-x-9">
              {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}
              {routes.map((route, index) =>
                !route.submenu ? (
                  <Link key={index} to={route.to} className={`${linkStyles}`}>
                    {route.name}
                  </Link>
                ) : (
                  <div key={index} className="relative">
                    {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}
                    <button
                      onClick={() =>
                        openFlyoutMenu === "about"
                          ? setOpenFlyoutMenu(null)
                          : setOpenFlyoutMenu("about")
                      }
                      type="button"
                      className={`${linkStyles} group inline-flex items-center space-x-1`}
                    >
                      <span>{route.name}</span>
                      {/* Item active: "text-gray-600", Item inactive: "text-gray-400" */}
                      <svg
                        className="text-gray-100 h-5 w-5 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>

                    <Transition
                      show={openFlyoutMenu === "about"}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                      className="origin-top-right absolute right-0 mt-3 w-56 rounded-md shadow-lg"
                    >
                      <div className="rounded-md bg-white shadow-xs">
                        <div
                          className="py-1"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="options-menu"
                        >
                          {route.submenu.map((subroute, index) => (
                            <Link
                              to={subroute.to}
                              key={index}
                              className="block px-4 py-2 text-base leading-6 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                              role="menuitem"
                            >
                              {subroute.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Transition>
                  </div>
                )
              )}
              <a
                href={`tel:${data.settings.phone}`}
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium bg-white hover:bg-gray-100 text-green-800 hover:text-green-600"
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  ></path>
                </svg>
                Call Us
              </a>
              <SocialIcons className="hidden lg:flex text-white hover:text-gray-200" />
            </nav>
          </div>
        </div>
      </div>

      <Transition
        show={isMobileNavOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
      >
        <div className="rounded-lg shadow-md">
          <div
            className="rounded-lg bg-green-800 shadow-xs overflow-hidden"
            onClick={() => setIsMobileNavOpen(false)}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="main-menu"
          >
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <Image
                  fixed={data.settings.logo.asset.small}
                  alt="Ashbrook Land Logo with Mountains"
                />
              </div>
              <div className="-mr-2">
                <button
                  onClick={() => setIsMobileNavOpen(false)}
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-300 hover:bg-green-600 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  aria-label="Close menu"
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3">
              {routes.map((route, index) => {
                if (!route.submenu) {
                  return (
                    <MobileLink
                      key={`mobile-link-${index}`}
                      route={route}
                      index={index}
                    />
                  )
                } else if (route.submenu) {
                  return (
                    <>
                      {route.submenu.map((r, i) => (
                        <MobileLink
                          key={`mobile-sublink-${i}`}
                          route={r}
                          index={i}
                        />
                      ))}
                    </>
                  )
                } else {
                  return null
                }
              })}
            </div>
            <div>
              <a
                href={`tel:${data.settings.phone}`}
                className="inline-flex w-full px-5 py-3 text-center justify-center items-center font-medium text-green-600 bg-white hover:bg-gray-100 hover:text-green-700 focus:outline-none focus:bg-gray-100 focus:text-green-700 transition duration-150 ease-in-out"
                role="menuitem"
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  ></path>
                </svg>
                Call Us
              </a>
            </div>
          </div>
        </div>
      </Transition>
    </header>
  )
}

export default Header

const MobileLink = ({ route, index }) => (
  <Link
    to={route.to}
    role="menuitem"
    className={`${
      index !== 0 && "mt-1"
    } block px-3 py-2 rounded-md text-base font-medium text-gray-50 hover:text-gray-200 hover:bg-green-600 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out`}
  >
    {route.name}
  </Link>
)

const routes = [
  {
    name: "Home",
    to: "/",
    submenu: null,
  },
  {
    name: "Properties",
    to: "/properties",
    submenu: null,
  },
  {
    name: "About",
    to: "/about",
    submenu: [
      {
        name: "About Us",
        to: "/about",
      },
      {
        name: "Buying Process",
        to: "/process",
      },
      {
        name: "FAQ",
        to: "/faq",
      },
    ],
  },
]
